@import '../../app/colors.scss';
@import '../../app/variables.scss';

@mixin navArea {
	height: 3rem;
	width: 3rem;
	border-radius: 50%;
	background: $color-white;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
	border: 1px solid #333;
	position: absolute;
	top: calc(50% - 0.5rem);
	transform: translateY(-50%) translateY(-1.5rem);
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2rem;
	cursor: pointer;
	&:focus {
		border: none;
		outline: none;
	}
	& > div {
		height: inherit;
	}
}
.carrousel {
	position: relative;
	.navBackArea {
		@include navArea;
		left: -0.1rem;
	}
	.navForwardArea {
		@include navArea;
		right: -0.1rem;
		&.navForwardAreaWithScroll {
			right: -1.7rem;
		}
	}
	.content {
		overflow: hidden;
		position: relative;
		.scrollContainer {
			display: flex;
			flex-wrap: nowrap;
			position: relative;
			transition: left 400ms ease-in-out;
			left: 0;

			.card {
				flex-grow: 0;
				flex-shrink: 0;
				margin-right: 1rem;
				width: 100%;
			}
		}
	}
	.navigationDots {
		width: 100%;
		display: flex;
		justify-content: center;
		height: 3rem;
		margin-top: 1rem;
		.dot {
			width: 1rem;
			height: 1rem;
			border-radius: 50%;
			background: $color-cta-block-and-keyline;
			margin: 1rem 0.5rem;
			cursor: pointer;
			&.selected {
				background: $color-selected-active-state;
				cursor: default;
			}
		}
	}
	&.promoListStyles {
		max-width: 100%;

		.navBackArea,
		.navForwardArea {
			display: none;
		}
		
		.navigationDots {
			justify-content: flex-start;
			position: absolute;
			bottom: 0;
			left: auto;
    		margin-left: -.5rem;
		}
	}
}

@media screen and (min-width: $desktop-breakpoint) {
	.carrousel {
		&.promoListStyles {
			max-width: 120rem;
			margin: 0 auto;
			width: 100%;
			
			.navBackArea,
			.navForwardArea {
				border-width: 0;
				box-shadow: none;
				background: none;
				font-size: 0;
				display: flex;
			}
			.navBackArea:before, 
			.navForwardArea:after {
				content: "\e91f";
				font-family: "icomoon", sans-serif;
				font-size: 2.4rem;
				color: $color-icon-link;
			}
			.navBackArea {
				left: -4.5rem;
				&:before {
					transform: rotate(-180deg);
				}
			}
			.navForwardArea {
				right: -4rem;
			}
			.navigationDots {
				bottom: 9rem;
			}
		}
	}
}
