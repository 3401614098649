@import "../../../app/colors.scss";

.benefits {
	margin-bottom: 3em;
	& > h4 {
		margin-bottom: 1rem;
	}
	& > ul {
		padding-left: 2rem;
		li {
			color: $color-icon-link;
			span {
				color: $color-body-copy;
			}
		}
	}
}
