@import '../../../app/colors.scss';

.paymentFormContainer {
    color: $color-body-copy;

    .paymentFormTitle {
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 2rem;
    }

    main {
        background-color: $color-white;
        box-shadow: 0px 0px 20px 0px $color-box-shadow;
    }

    .paymentForm {
        padding: 1rem 2rem 3rem 2rem;

        .submitPaymentBtn {
            width: 100%;
        }

        .applyclearBtn {
            font-weight: 900;
            background: none;
            color: inherit;
            border: none;
            font-size: 14px;
            margin-left: auto;
            :enabled {
                cursor: pointer;
            }
        }

        .clearBtn {
            @extend .applyclearBtn;
            padding: 0;
            cursor: pointer;
        }

        .ticketPrice {
            display: flex;
            margin-top: 10px;
        }

        .showPromoCode {
            @extend .ticketPrice;
            margin-top: 5px;
            font-size: 14px;
        }

        .pricemargin {
            margin-left: auto;
        }

        .discountPrice {
            color: $color-warning-red;
            font-weight: 600;
        }

        .errorMsg {
            @extend .discountPrice;
            margin-bottom: 10px;
            font-size: 14px;
        }
    }

    .paymentFormTotal {
        margin-top: 16px;
        p {
            display: flex;
            justify-content: space-between;
        }
        .paymentFormTotalToPay {
            font-weight: bold;
        }
    }

    .promoCodes {
        display: flex;
        background: #fafafa;
        border-radius: 4px;
        color: #000000;
        margin: 12px auto;
        padding: 10px;
        text-align: center;
        width: 100%;

        input {
            width: calc(100% - 75px);
            border: none;
            padding: 10px;
            background-color: #fafafa;
            font-size: 14px;
            line-height: 16px;
        }

        input:focus {
            outline: none;
            background: #fafafa;
        }

        .applyPromoCodes {
            @extend .applyclearBtn;
            padding: 10px;
        }

        input::placeholder {
            color: #000;
            opacity: 1;
            font-weight: 500;
            font-size: 14px;
        }

        input:-ms-input-placeholder {
            color: #000;
            font-weight: 500;
            font-size: 14px;
        }

        input::-ms-input-placeholder {
            color: #000;
            font-weight: 500;
            font-size: 14px;
        }
    }
}

.error {
    color: $color-warning-red;
}

.paymentFormErrorBlock {
    margin-top: 10px;
}
