@import '../../app/colors.scss';
@import '../../app/variables.scss';

.container {
    margin: 0 auto;
    padding: 3rem 13rem;
    @media screen and (max-width: $mobile-breakpoint) {
        width: 100%;
        box-sizing: border-box;
        padding: 3rem 2rem;
    }
}

.topContainer {
    background-color: $color-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 10rem;
    padding-bottom: 10rem;

    .textSecondLine {
        color: rgb(0, 0, 0);
        font-size: 2.8rem;
        font-weight: bold;
        text-align: center;
    }

    .textFirstLine {
        color: rgb(85, 89, 94);
        font-size: 1.6rem;
        font-weight: bold;
        text-transform: uppercase;
    }

    .inputs {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 70%;
        @media screen and (max-width: $mobile-breakpoint) {
            flex-direction: column;
        }

        input {
            border: 0.1rem solid $color-cta-block-and-keyline;
            height: 4.8rem;
            width: 100%;
            margin: 2rem 1rem;
            font-size: 1.6rem;

            &::placeholder {
                color: rgb(85, 89, 94);
                font-size: 1.6rem;
                font-weight: bold;
                text-transform: capitalize;
            }
        }
    }
}

.centralContainer {
    background-color: rgb(45, 20, 110);
    color: white;
    display: flex;

    .centalContainerItems {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $mobile-breakpoint) {
            flex-direction: column;
        }
        .leftSection {
            margin-bottom: 2rem;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: flex-start;
            @media screen and (max-width: $mobile-breakpoint) {
                flex-direction: row;
            }
            .logo {
                display: flex;
                justify-content: flex-start;
            }
        }

        .centralSection {
            padding: 0 0.5rem;
            margin-top: 6rem;
            @media screen and (max-width: $mobile-breakpoint) {
                margin-top: 0;
            }

            .accordion {
                margin: 0;
                padding: 0;
                list-style: none;
            }

            .accordionItem {
                list-style: none;
                border-bottom: 0.1rem solid $color-white;
                margin-bottom: 1.5rem;
                font-size: 1.8rem;
                font-weight: bold;
                min-width: 30rem;

                .rect {
                    position: relative;
                    height: 4rem;
                    width: 4rem;
                    min-width: 4rem;
                    background-color: $color-light-blue;
                    margin-left: 1.5rem;

                    &:after {
                        content: '';
                        position: absolute;
                        background: #fff;
                        top: 50%;
                        left: 25%;
                        right: 25%;
                        height: 2px;
                        transform: translateY(-50%);
                    }
                }

                .accordionPanel[hidden] {
                    display: none;
                }

                .accordionHeader {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    min-height: 4rem;
                    padding: 0;
                    margin: 0;
                    border: 0;
                    background: none;
                    text-align: left;

                    > a {
                        color: $color-white;
                    }

                    &:not(.expanded) {
                        .rect:before {
                            content: '';
                            position: absolute;
                            background: #fff;
                            left: 50%;
                            top: 25%;
                            bottom: 25%;
                            width: 2px;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }

        .rightSection {
            max-width: 35%;
            display: flex;
            flex-direction: column;
            margin-top: 5.4rem;
            @media screen and (max-width: $mobile-breakpoint) {
                max-width: 80%;
                margin-top: 0;
            }
            .rightSectionTitle {
                font-size: 2.1rem;
                font-weight: bold;
                margin-top: 0;
            }

            .button {
                background-color: inherit;
                border: 0.1rem solid $color-light-blue;
                width: 19rem;
                margin-top: 1.5rem;
                margin-bottom: 1.6rem;
                min-height: 4.8rem;

                a {
                    font-size: 0.9em;
                    color: $color-white;
                    font-weight: 700;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .storesLinks {
                margin-top: 0.5rem;
                width: 100%;
                display: flex;
                .storeLink {
                    margin-right: 1.5rem;
                }
            }
        }
    }

    .socialButtonsContainer {
        display: flex;

        .socialButton {
            height: 4rem;
            width: 4rem;
            padding: 0.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 0.1rem solid $color-cta-block-and-keyline;
            margin-right: 1rem;

            a {
                display: flex;
            }
        }
    }

    .socialButtonWithLinks {
        margin-top: 1.5rem;
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }

    .socialButtonWithLogo {
        @media screen and (max-width: $mobile-breakpoint) {
            display: none;
        }
    }

    ul.linksBlock {
        font-size: 1.6rem;
        font-weight: bold;
        margin-top: 1rem;
        margin-bottom: 0.5rem;
        color: $color-light-blue;
        padding: 0;

        li {
            display: block;
            cursor: pointer;

            a {
                color: $color-light-blue;

                &:hover {
                    color: $color-hover;
                    text-decoration: none;
                }
            }
        }
    }
}

.bottomContainer {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 5rem;

    & span:last-child {
        display: none;
    }
}

.seoLinks {
    @include hiddenBlock();
}

.safeSpacesContainer,
.safeSpacesContainerMobile {
    display: none;
    flex-direction: column;

    span {
        margin-bottom: 16px;
    }

    button {
        background: transparent;
        border: none;

        img {
            height: 100%;
            width: 100%;
            max-width: 130px;
        }
    }
}

.safeSpacesContainerMobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    word-break: break-word;
    max-width: 230px;
}

@media (min-width: 550px) {
    .safeSpacesContainer {
        display: flex;
    }

    .safeSpacesContainerMobile {
        display: none;
    }
}
