@import '../../app/colors.scss';
.popupContainer {
    .popupContent {
        padding: 1rem;
        user-select: none;

        & .popupTitle {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            & > p {
                font-weight: bold;
            }

            & > div {
                padding: 0 1rem;
                font-weight: bold;
            }

            & > span {
                cursor: pointer;
            }
        }

        & .popupFooter {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;

            & > span {
                color: $color-text-link;
                padding: 0 0.5rem;
                cursor: pointer;
            }
        }
    }
}
