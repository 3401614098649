@import "../../../app/colors.scss";
@import "../../../app/variables.scss";

.printForm,
.postalForm {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	padding: 1.4rem 2rem 0;
	.printFormNameRow {
		display: flex;
		flex-direction: column;
	}
	&:last-child {
		margin: 0;
	}
}

.formInput {
	display: flex;
	align-items: center;
}

.formInput,
.formNameInput {
	margin: 0.5rem 0;
}

@media screen and (min-width: $desktop-breakpoint) {
	.printForm {
		.printFormNameRow {
			flex-direction: row;
			justify-content: flex-start;
			& > div {
				max-width: 26rem;
			}
			.formNameInput {
				max-width: 26rem;
				margin-bottom: 0;
			}
			& *:first-child {
				margin-right: 1rem;
			}
		}
	}
	.formInput {
		max-width: 33.5rem;

		& > div {
			max-width: 33.5rem;
		}
	}
}

.emailForm {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	.emailFormInfo {
		font-size: 1.6rem;
		margin-bottom: 1.5rem;
		& > strong {
			font-size: 1.4rem;
		}
	}
	.emailFormField {
		width: 100%;
		.emailFormFieldControls {
			margin-top: 1.8rem;
			display: flex;
			justify-content: flex-end;
			min-height: fit-content;
			& *:first-child {
				margin-right: 2rem;
			}
		}
	}
}

.addressForm {
	& > h4 {
		font-size: 1.4rem;
		margin: 0;
	}
	& > p {
		font-size: 1.6rem;
	}
	.addressFormSubmitButton {
		margin: 1rem 0;
		width: 100%;
	}
	.addressFormManual {
		margin-top: 1.7rem;
		.addressBlock {
			margin-bottom: 2rem;
		}
	}
	.addressFormInput {
		margin-top: 1rem;
	}
	.addressFormManualControls {
		display: flex;
		justify-content: flex-end;
		margin-top: 2rem;

		& *:first-child {
			margin-right: 2rem;
		}
	}
}

.postDeliveryTypeForm {
	padding: 2rem;
	.radioButtonItems {
		margin-top: 1.8rem;
	}
}

.infoPopUpBtn {
	cursor: pointer;
	margin-left: 0.7rem;
	margin-top: 0.7rem;
}

.errorMessage {
	margin-bottom: 0.5rem;
	margin-left: 1rem;
	color: red;
}

.emptyAddressError {
	margin-bottom: 0.5rem;
	color: $color-error-warning-text;
	align-items: center;
	display: flex;
}

.emptyAddressError svg {
	margin-left: 0.5rem;
}

.error {
	margin: 0;
}