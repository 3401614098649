@import "../../../app/colors.scss";

.basketItem {
	position: relative;
	background: $color-white;
	width: 100%;
	max-width: 42rem;
	height: 18rem;
	box-shadow: 0px 0px 20px 0px $color-box-shadow;
	display: flex;
	margin: 1.5rem auto;

	.ticketStatus {
		position: absolute;
		top: -1rem;
		left: 2rem;
		background: $color-warning-muted;
		border-radius: 0px;
		border: 1px solid $social-google;
		font-size: 1.4rem;
		font-weight: bold;
		padding: 0 1rem;
		color: $social-google;
	}

	.basketItemContent {
		display: flex;
		flex-grow: 1;
		padding: 1.4rem 2.6rem 2.4rem;
		overflow: auto;

		.leftSide {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			flex-grow: 1;
			.ticketTitle {
				font-size: 1.8rem;
				margin: 0 0 2.4rem;
			}
			.region {
				font-size: 1.6rem;
				margin-bottom: 0.8rem;
			}
			.validate {
				font-size: 1rem;
				color: $ticket-gray;
				margin: 0;
			}
			.validity {
				font-size: 1.4rem;
				font-weight: bold;
				margin: 0;
				color: $ticket-gray;
			}
			.description {
				font-size: 1.4rem;
				margin: 0;
			}
		}

		.rightSide {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			.price {
				margin-bottom: 2.6rem;
			}
		}

		.travelTime {
			color: $color-naw-heading-grey;
			font-size: 0.7rem;
			padding: 0.5rem 0;
		}

		.period {
			padding-bottom: 1rem;
			font-weight: 700;
			color: $color-black;
			font-size: 1.3rem;
		}

		.activationMessage {
			color: $color-black;
			font-size: 1.3rem;
			font-weight: bold;
			padding: 1rem 0;
		}

		.ticketFooter {
			display: flex;
			flex-direction: row;
			justify-content: center;
			color: $color-black;
			font-size: 0.7rem;
			font-weight: 500;
		}
	}

	.basketItemMarker {
		flex-shrink: 0;
		width: 0.6rem;
		height: 100%;
	}
}
