@import "../../../app/colors.scss";

.ticketListContainer {
	display: flex;
	flex-direction: column;
	.ticketListItem {
		width: 100%;
		.ticketListItemTitle {
			margin: 1rem 0 0.4rem 2rem;
			font-size: 1.4rem;
		}
	}
}

.ticketListItemName {
	position: relative;
	background-color: $color-ab-muted-1;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 2rem 1.3rem;
	margin: 0;
	& > h2 {
		font-size: 1.6rem;
		margin: 0;
	}
}

.ticketListItemMarker {
	height: 1.6rem;
	width: 0.4rem;
	position: absolute;
	left: 0;
	bottom: 2.3rem;
}
