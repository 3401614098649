@import "../../../app/colors.scss";

.detailsContainer {
	background-color: $color-white;
	& > hr {
		width: 97%;
		margin: 1rem auto 1.5rem;
		color: $color-hint-text;
	}
	.detailsHeader {
		font-size: 1.6rem;
		margin: 1rem 2rem;
	}
	.detailsFooter {
		display: flex;
		height: max-content;
		flex-direction: column;
		padding: 0 2.2rem 2rem;
	}
}

.deliveryOption {
	margin-top: 1rem;

	.deliveryOptionHeader {
		background-color: $color-white;
		padding: 2rem 2rem 1.4rem;
		box-shadow: 0px 0px 20px 0px $color-box-shadow;

		.deliveryOptionHeaderContent {
			display: flex;
			justify-content: space-between;
			.textContent {
				width: 100%;
				.textContentTitle {
					font-weight: bold;
				}
				.textContentDescription,
				.textContentTitle {
					font-size: 1.5rem;
					line-height: 2rem;
				}
				.textReadMoreLink {
					color: $color-text-link;
					font-size: 16px;
					font-weight: bold;
				}
			}
			.iconContainer {
				margin-left: 2.7rem;
			}
		}
	}

	&.expanded {
		.expandable {
			display: block;
		}
	}

	.expandable {
		display: none;
		background-color: $color-white;

		& > hr {
			width: 97%;
			margin: 0 auto;
			color: $color-hint-text;
		}
	}
}
