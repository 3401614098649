// Primary colors
$color-cta-block-and-keyline: #5ccbd7;
$color-hover: #98c818;
$color-text-link: #2d146e;
$color-selected-active-state: #911d8b;
$color-warning: #ff8a00;
$color-warning-red: #ff0000;
$color-white: #ffffff;
$color-default-bg: #55595d;
$color-border-grey: #e2e2e2;
$color-secondary-header: #92438b;
$color-black: #000000;

// Supporting colors
$color-ab-muted-1: #e5f7f9;
$color-ab-muted-2: #edf9fb;
$color-ab-muted-3: #f7fdfd;
$color-positive-muted: #eaf4d1;
$color-warning-muted: #ffefd1;
$color-light-grey: #f7f7f7;
$color-light-grey-2: #f9f9f9;
$color-light-grey-3: #e6e6e6;
$color-light-blue: #00a1ac;
$color-naw-heading-grey: #909090;
$color-nav-heading-bg: #e4f7f9;
$adult-ticket-color: #009da5;
$child-ticket-color: #96cbd7;
$family-ticket-color: #fea702;
$group-ticket-color: #8d1189;
$student-ticket-color: #fe6a01;
$color-box-shadow: rgba(0, 0, 0, 0.1);
$icon-btn-blue: #1ca8b2;
$border-gray: rgba(85, 89, 94, 0.3);
$ticket-blue: #0aa6b1;
$ticket-gray: rgb(85, 89, 94);
$ticket-background: #f6f6f6;
$social-facebook: #3b5899;
$social-google: #aa4b39;
$bus-status-orange: #e35b0d;
$bus-status-red: #e20000;
$color-grey: #11172b;
$color-secondary-text: #27262c;
$chat-primary: #5c3dbe;
$zone-border: #e5e5e5;
$time-line: #008789;

// body copy
$color-body-copy: #000;
$color-hint-text: #55595e;
$color-hint-text-light: rgba(85, 89, 94, 0.16);
$color-error-warning-text: #e25300;
$color-positive-success-text: #3d8704;
$color-green-light: #cfe1c1;
$color-hover-text: #7a9a01;
$color-icon-link: #00a1ac;
$color-placeholder-text: #353535;
