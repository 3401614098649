@import '../../app/colors.scss';

.mainContainer {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    position: fixed;
    top: 90%;
    right: 0;
    z-index: 10000;

    .chatContainer {
        display: flex;
        align-items: center;
        cursor: pointer;
        border: none;
        background: none;
        outline: none;
        padding: 0;
        margin: 0;

        .labelText {
            background: $chat-primary;
            padding: 0.9rem 4.7rem 0.8rem 2rem;
            color: white;

            .labelTitle {
                font-size: 1.6rem;
                line-height: 1.8rem;
                font-weight: bold;
            }

            .labelSubtitle {
                font-size: 1.4rem;
                line-height: 1.8rem;
            }
        }

        .chatIconContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            background: $chat-primary;
            height: 7rem;
            width: 7rem;

            .chatIconBackground {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0.7rem;
                background: $color-text-link;
                height: 5.5rem;
                width: 5.5rem;
            }
        }
    }
}
