@import "../../app/colors.scss";
@import "../../app/variables.scss";

.basket {
	padding: 0;
}

.basketContent {
	display: flex;
	margin: 0 auto;
	flex-direction: column;
	width: 100%;

	.basketDeliveryOptions {
		width: 100%;
		margin-bottom: 2rem;
	}

	.basketPaymentDetails {
		position: relative;
	}

	aside {
		position: relative;
		width: 100%;
	}
}

@media (min-width: $desktop-breakpoint) {
	.basket {
		padding: 0;
	}

	.basketContent {
		flex-direction: row;
		justify-content: space-between;
		width: 78%;
		min-width: 99rem;

		.carouselContainer {
			display: block;
		}

		.basketDeliveryOptions {
			margin-top: 2.5rem;
			width: 53%;
		}

		.basketPaymentDetails {
			top: -2rem;
		}

		aside {
			width: 38%;
			min-width: 45rem;
		}
	}
}

.carouselContainer {
	padding: 1rem 0;
}
