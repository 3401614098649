@import "../../app/variables.scss";

.datePicker {
	position: relative;
	width: 100%;

	@media screen and (min-width: $desktop-breakpoint) {
		max-width: 26rem;
	}
}
